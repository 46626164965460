import {
  createApi,
  type BaseQueryFn,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react"
import { createBBoxObservableDto, createTimeRangeObservableDto } from "../../api/observable.helpers"
import {
  createEditMainInfoDto,
  mapCollectionIdToUpdateDatasetDto,
  mapMetadataToUpdateCollectionDto,
  mapMetadataToUpdateDatasetDto,
  type MainInfo,
} from "../../api/requests.types"
import { createDataCollectionDto, createDatasetDto } from "../../api/requests.utils"
import { type DeleteResponse } from "../../api/response.types"
import {
  createTableSchemaDto,
  type CreateSchemaDto,
  type DataPointsCreateDto,
  type DataPointsNDJsonDto,
  type DatasetRow,
  type DtaPointsCreateResponse,
  type SchemaField,
  type TableSchemaGetDto,
} from "../../api/schema.types"
import type {
  DataCollection,
  FillableMetadataDataCollection,
  createDataCollectionFrontend,
} from "../../frontendTypes/dataCollection.types"
import { getResourceKindForResourceType } from "../../frontendTypes/datasetType.helpers"
import {
  type CreateDataSet,
  type DataSet,
  type FillableMetadataDataset,
  type TimeRangeMiliSeconds,
  type TimeRangeSeconds,
} from "../../frontendTypes/datasets.types"
import { mapToDataset } from "../../frontendTypes/frontendMappers"
import { jsSDK } from "../../sdk/jsSDK"
import { type DataCollectionDtoGet } from "../../sdk/utils/entities/sdk.dataCollection.types"
import {
  type APIResultsDto,
  type AttributeDto,
  type DatasetDtoGet,
  type EditMetadataDto,
  type Facets,
} from "../../sdk/utils/entities/sdk.dataset.types"
import type { ObservableDtoGet, TimeRangeObservableValue } from "../../sdk/utils/entities/sdk.observable.types"
import { type ResourceTypeOdp } from "../../sdk/utils/entities/sdk.resource.types"
import type { LimitedStreamOptions } from "../../sdk/utils/requests/sdk.request.types"
import { createResponse, getIdFromObservableRef } from "./queries.helpers"

export enum DatasetApiTags {
  UserDatasets = "UserDatasets",
  UserDataCollections = "UserDataCollections",
  Observables = "Observables",
  Schema = "Schema",
  DataSample = "DataSample",
  BBoxFilteredDatasetIds = "BBoxFilteredDatasetIds",
  TimeFilteredDatasetIds = "TimeFilteredDatasetIds",
}

export const queryMock: BaseQueryFn<unknown, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta> = async () => {
  throw new Error("RTK base query not allowed. Use ODP JS SDK instead.")
}

export const datasetsApi = createApi({
  reducerPath: "datasetsApi",
  tagTypes: Object.values(DatasetApiTags),
  baseQuery: queryMock,
  endpoints: builder => ({
    getCollectionDatasetsAPI: builder.query<
      DataSet[],
      { collectionId: string; published?: boolean; searchString?: string }
    >({
      queryFn: async ({ collectionId, published, searchString }) => {
        return await createResponse<APIResultsDto<DatasetDtoGet>, DataSet[]>(
          async () =>
            await jsSDK.catalog.getDatasetSearchResult({
              collectionId,
              isPublic: published,
              typedSearchString: searchString,
            }),
          el => el.results.map(mapToDataset)
        )
      },
      providesTags: [DatasetApiTags.UserDatasets],
    }),

    getDatasetById: builder.query<DataSet, string>({
      queryFn: async datasetID => {
        return await createResponse<DatasetDtoGet, DataSet>(
          async () => await jsSDK.datasets.getDatasetByIdApi(datasetID),
          mapToDataset
        )
      },
      providesTags: [DatasetApiTags.UserDatasets],
    }),

    getObservablesForResource: builder.query<
      ObservableDtoGet<any>[],
      { resourceId: string; resourceType: ResourceTypeOdp }
    >({
      queryFn: async params => {
        return await createResponse<APIResultsDto<ObservableDtoGet<any>>, ObservableDtoGet<any>[]>(
          async () => await jsSDK.datasets.getObservablesForResource(params),
          el => el.results
        )
      },
      providesTags: [DatasetApiTags.Observables],
    }),

    getSchemaByDatasetNameId: builder.query<TableSchemaGetDto, string>({
      queryFn: async datasetID => {
        return await createResponse<TableSchemaGetDto, TableSchemaGetDto>(
          async () => await jsSDK.datasets.getSchemaByDatasetNameId(datasetID),
          el => el
        )
      },
      providesTags: (_, __, id) => [{ type: DatasetApiTags.Schema, id }],
    }),

    getDataPointsByDatasetNameId: builder.query<
      DatasetRow[],
      { datasetNameId: string; limitedStreamOptions: LimitedStreamOptions }
    >({
      queryFn: async ({ datasetNameId, limitedStreamOptions }) => {
        return await createResponse<DataPointsNDJsonDto, DatasetRow[]>(
          async () => await jsSDK.datasets.getDatapointsByDatasetNameId({ datasetNameId, limitedStreamOptions }),
          el => el
        )
      },
      providesTags: (_, __, args) => [{ type: DatasetApiTags.DataSample, id: args.datasetNameId }],
    }),

    createDataPointsByDatasetNameId: builder.mutation<
      DtaPointsCreateResponse,
      { datasetNameId: string; dto: DataPointsCreateDto }
    >({
      queryFn: async ({ datasetNameId, dto }) => {
        return await createResponse<DtaPointsCreateResponse, DtaPointsCreateResponse>(
          async () => await jsSDK.datasets.createDataPointsByNameId({ datasetNameId, dataPointsDto: dto }),
          el => el
        )
      },
      invalidatesTags: (_, __, arg) => [{ type: DatasetApiTags.DataSample, id: arg.datasetNameId }],
    }),

    addSchemaByDatasetNameId: builder.mutation<CreateSchemaDto, { datasetNameId: string; schemaFields: SchemaField[] }>(
      {
        queryFn: async ({ datasetNameId, schemaFields }) => {
          return await createResponse<CreateSchemaDto, CreateSchemaDto>(
            async () =>
              await jsSDK.datasets.addDatasetSchemaByNameId({
                datasetNameId,
                schemaDto: createTableSchemaDto(schemaFields),
              }),
            el => el
          )
        },
        invalidatesTags: (_, __, arg) => [{ type: DatasetApiTags.Schema, id: arg.datasetNameId }],
      }
    ),

    deleteSchemaByNameId: builder.mutation<DeleteResponse, string>({
      queryFn: async datasetNameId => {
        return await createResponse<DeleteResponse, DeleteResponse>(
          async () => await jsSDK.datasets.deleteDatasetSchemaByNameId(datasetNameId),
          el => el
        )
      },
      invalidatesTags: (_, __, datasetNameId) => [{ type: DatasetApiTags.Schema, id: datasetNameId }],
    }),

    getDatasetByName: builder.query<DataSet, string>({
      queryFn: async nameID => {
        return await createResponse<DatasetDtoGet, DataSet>(
          async () => await jsSDK.datasets.getDatasetByNameApi(nameID),
          mapToDataset
        )
      },
      providesTags: [DatasetApiTags.UserDatasets],
    }),

    addDataset: builder.mutation<DataSet, CreateDataSet>({
      queryFn: async dataset => {
        return await createResponse<DatasetDtoGet, DataSet>(
          async () => await jsSDK.datasets.addDataset(createDatasetDto(dataset)),
          mapToDataset
        )
      },
      invalidatesTags: [DatasetApiTags.UserDatasets],
    }),

    addDataCollection: builder.mutation<DataCollectionDtoGet, createDataCollectionFrontend>({
      queryFn: async createDto => {
        return await createResponse<DataCollectionDtoGet, DataCollectionDtoGet>(
          async () => await jsSDK.datasets.addDataCollection(createDataCollectionDto(createDto)),
          el => el
        )
      },
      invalidatesTags: [DatasetApiTags.UserDataCollections],
    }),

    addBBoxObservable: builder.mutation<
      ObservableDtoGet<GeoJSON.Geometry>,
      { nameId: string; type: ResourceTypeOdp; geometry: GeoJSON.Polygon }
    >({
      queryFn: async args => {
        return await createResponse<ObservableDtoGet<GeoJSON.Geometry>, ObservableDtoGet<GeoJSON.Geometry>>(
          async () => await jsSDK.datasets.addObservable<GeoJSON.Geometry>(createBBoxObservableDto(args)),
          el => el
        )
      },
      invalidatesTags: [DatasetApiTags.Observables],
    }),

    addTimerangeObservable: builder.mutation<
      ObservableDtoGet<TimeRangeObservableValue>,
      { nameId: string; type: ResourceTypeOdp; timeRange: TimeRangeMiliSeconds }
    >({
      queryFn: async args => {
        return await createResponse<
          ObservableDtoGet<TimeRangeObservableValue>,
          ObservableDtoGet<TimeRangeObservableValue>
        >(
          async () => await jsSDK.datasets.addObservable<TimeRangeObservableValue>(createTimeRangeObservableDto(args)),
          el => el
        )
      },
      invalidatesTags: [DatasetApiTags.Observables],
    }),

    updateMetadata: builder.mutation<
      DataSet,
      { datasetId: string; metadata?: FillableMetadataDataset; facets?: Facets }
    >({
      queryFn: async ({ datasetId, metadata, facets }) => {
        const dto = mapMetadataToUpdateDatasetDto(metadata, facets)
        return await createResponse<DatasetDtoGet, DataSet>(
          async () => await jsSDK.datasets.updateResourceByIdApi(datasetId, dto),
          mapToDataset
        )
      },
      invalidatesTags: (_, error) => (error ? [] : [DatasetApiTags.UserDatasets]),
    }),

    updateParrentCollection: builder.mutation<DataSet, { datasetId: string; collectionId: string }>({
      queryFn: async ({ datasetId, collectionId }) => {
        const dto = mapCollectionIdToUpdateDatasetDto(collectionId)
        return await createResponse<DatasetDtoGet, DataSet>(
          async () => await jsSDK.datasets.updateResourceByIdApi(datasetId, dto),
          mapToDataset
        )
      },
      invalidatesTags: [DatasetApiTags.UserDatasets],
    }),

    updateCollectionMetadata: builder.mutation<DataSet, { datasetId: string; update: FillableMetadataDataCollection }>({
      queryFn: async ({ datasetId, update }) => {
        const dto: EditMetadataDto = mapMetadataToUpdateCollectionDto(update)
        return await createResponse<DatasetDtoGet, any>(
          async () => await jsSDK.datasets.updateResourceByIdApi(datasetId, dto),
          mapToDataset
        )
      },
      invalidatesTags: (_, error) => (error ? [] : [DatasetApiTags.UserDataCollections]),
    }),

    updateResourceMetadata: builder.mutation<DataSet | DataCollection, { resourceId: string; update: MainInfo }>({
      queryFn: async ({ resourceId: datasetId, update }) => {
        const dto: EditMetadataDto = createEditMainInfoDto(update)
        return await createResponse<DatasetDtoGet, any>(
          async () => await jsSDK.datasets.updateResourceByIdApi(datasetId, dto),
          mapToDataset
        )
      },
      invalidatesTags: [DatasetApiTags.UserDataCollections, DatasetApiTags.UserDatasets],
    }),

    updateAttributes: builder.mutation<DataSet, { datasetId: string; attributes: AttributeDto[] }>({
      queryFn: async ({ datasetId, attributes }) => {
        return await createResponse<DatasetDtoGet, DataSet>(
          async () =>
            await jsSDK.datasets.updateResourceByIdApi(datasetId, {
              spec: {
                attributes,
              },
            }),
          mapToDataset
        )
      },
      invalidatesTags: [DatasetApiTags.UserDatasets],
    }),

    deleteResource: builder.mutation<{ status: string }, { resourceId: string; type: "Dataset" | "DataCollection" }>({
      queryFn: async ({ resourceId }) => {
        return await createResponse(
          async () => await jsSDK.datasets.deleteDataset(resourceId),
          el => el
        )
      },
      invalidatesTags: (_, error, args) => {
        if (error) return []
        if (args.type === "Dataset") return [DatasetApiTags.UserDatasets]
        else return [DatasetApiTags.UserDataCollections]
      },
    }),

    deleteObservable: builder.mutation<{ status: string }, string>({
      queryFn: async observableId => {
        return await createResponse(
          async () => await jsSDK.datasets.deleteDataset(observableId),
          el => el
        )
      },
      invalidatesTags: [DatasetApiTags.Observables],
    }),

    getIdsFromBBoxObservable: builder.query<string[], { bbox: GeoJSON.Polygon; resourceType: ResourceTypeOdp }>({
      queryFn: async ({ bbox, resourceType }) => {
        return await createResponse(
          async () => {
            return await jsSDK.datasets.getGeometryObservables(bbox, getResourceKindForResourceType(resourceType))
          },
          res => res.results.map(observable => getIdFromObservableRef(observable.spec.ref))
        )
      },
      providesTags: [DatasetApiTags.BBoxFilteredDatasetIds],
    }),

    getIdsFromTimerangeObservable: builder.query<string[], TimeRangeSeconds>({
      queryFn: async timeRange => {
        return await createResponse(
          async () => {
            return await jsSDK.datasets.getTimeRangeObservables(timeRange)
          },
          res => res.results.map(observable => getIdFromObservableRef(observable.spec.ref))
        )
      },
      providesTags: [DatasetApiTags.TimeFilteredDatasetIds],
    }),
  }),
})

export const {
  useAddDatasetMutation,
  useGetDatasetByIdQuery,
  useGetDatasetByNameQuery,

  useUpdateMetadataMutation,
  useUpdateAttributesMutation,
  useUpdateParrentCollectionMutation,

  useAddDataCollectionMutation,
  useGetCollectionDatasetsAPIQuery,
  useUpdateCollectionMetadataMutation,

  useDeleteResourceMutation,
  useUpdateResourceMetadataMutation,

  useGetObservablesForResourceQuery,
  useAddBBoxObservableMutation,
  useAddTimerangeObservableMutation,
  useDeleteObservableMutation,

  useGetIdsFromBBoxObservableQuery,
  useGetIdsFromTimerangeObservableQuery,

  useDeleteSchemaByNameIdMutation,
  useGetSchemaByDatasetNameIdQuery,
  useAddSchemaByDatasetNameIdMutation,

  useGetDataPointsByDatasetNameIdQuery,
  useCreateDataPointsByDatasetNameIdMutation,
  useLazyGetDataPointsByDatasetNameIdQuery,
} = datasetsApi
