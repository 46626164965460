import type { PostHog } from "posthog-js"
import { checkIfProduction } from "../utils"

export const posthogInit = (posthog: PostHog) => {
  const isProduction = checkIfProduction()

  if (isProduction) {
    posthog.init("phc_mLTPnFTSJa39ojWKe08q1xrQEbm2eQKESEiTTnDmNuB", {
      api_host: "https://eu.i.posthog.com",
      disable_external_dependency_loading: true, // Optional - will ensure we never try to load extensions lazily
    })
  } else {
    posthog.init("fake_token", {
      autocapture: false,
      loaded: function (ph: any) {
        ph.opt_out_capturing() // opts a user out of event capture
        ph.set_config({ disable_session_recording: true })
      },
    })
  }
}
