import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { type MetadataValidationFormCollection } from "../../../pages/MyDataset/components/MyDatasetMetadata/components/EditMetadataDialog/editMetadataDialog.helpers"
import type { MetadataFilters } from "../queries/filtering.helpers"

interface DatasetsState {
  showAddDataCollectionPopup: boolean
  showAddDatasetPopup: boolean
  metadataRulesCollection: MetadataValidationFormCollection
  tabularUploadProgressStatus: TabularUploadProgressStatus
  recentlyDeletedFileIds: string[] // there is a delay in file deletion process on the api side which causes deleted files to display for max of 10 seconds
  filters: MetadataFilters & { showInternallyShared?: boolean }
}

interface TabularUploadProgressStatus {
  fileOpened?: boolean
  schemaCreated?: boolean
  filleMatchesSchema?: boolean
}

const defualtFilters: MetadataFilters = { searchString: "" }

const initialState: DatasetsState = {
  showAddDataCollectionPopup: false,
  showAddDatasetPopup: false,
  metadataRulesCollection: {},
  tabularUploadProgressStatus: {},
  recentlyDeletedFileIds: [],
  filters: defualtFilters,
}

const datasetSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    setAddDataCollectionPopupVisibleRA(state, action: PayloadAction<boolean>) {
      state.showAddDataCollectionPopup = action.payload
    },
    setAddDatasetPopupVisibleRA(state, action: PayloadAction<boolean>) {
      state.showAddDataCollectionPopup = action.payload
    },

    clearTabularProgresStatus(state) {
      state.tabularUploadProgressStatus = {}
    },
    updateTabularProgressStatus(
      state,
      action: PayloadAction<{ type: keyof TabularUploadProgressStatus; value: boolean | undefined }>
    ) {
      if (action.payload.type === "fileOpened") state.tabularUploadProgressStatus.fileOpened = action.payload.value
      if (action.payload.type === "schemaCreated") {
        state.tabularUploadProgressStatus.schemaCreated = action.payload.value
      }
      if (action.payload.type === "filleMatchesSchema") {
        state.tabularUploadProgressStatus.filleMatchesSchema = action.payload.value
      }
    },
    addRecentlyDeletedFileIdRA(state, action: PayloadAction<string>) {
      state.recentlyDeletedFileIds.push(action.payload)
    },

    setMyDataSearchStringRA(state, action: PayloadAction<string>) {
      state.filters.searchString = action.payload
    },
    setMyDataYearsFilterRA(state, action: PayloadAction<{ startYear?: number; endYear?: number }>) {
      state.filters.startYear = action.payload.startYear
      state.filters.endYear = action.payload.endYear
    },
    setMyDataBounidingBoxFilterRA(state, action: PayloadAction<GeoJSON.Polygon | undefined>) {
      state.filters.boundingBox = action.payload
    },
    setMyDataShowInternallySharedFilterRA(state, action: PayloadAction<boolean | undefined>) {
      state.filters.showInternallyShared = action.payload
    },
    resetMyDataFiltersRA(state) {
      state.filters = defualtFilters
    },
  },
})

export const {
  setAddDataCollectionPopupVisibleRA,
  setAddDatasetPopupVisibleRA,
  updateTabularProgressStatus,
  clearTabularProgresStatus,
  addRecentlyDeletedFileIdRA,
  setMyDataSearchStringRA,
  setMyDataYearsFilterRA,
  setMyDataBounidingBoxFilterRA,
  setMyDataShowInternallySharedFilterRA,
  resetMyDataFiltersRA,
} = datasetSlice.actions
export const { reducer: datasetsReducer } = datasetSlice
