import React from "react"
import type { DataCollection } from "../../../utils/frontendTypes/dataCollection.types"
import { toSimpleDate } from "../../../utils/frontendTypes/datasetType.helpers"
import { createContact } from "../../../utils/sdk/helpers"
import DataDetailsTable from "./DataDetailsTable/DataDetailsTable"

const MetadataTable = (p: DataCollection) => {
  const contact =
    p.metadata.contactEmail && p.metadata.contactLastName
      ? createContact({
          firstName: p.metadata?.contactFirstName,
          lastName: p.metadata?.contactLastName,
          email: p.metadata?.contactEmail,
        })
      : "undefined"

  const data = {
    "Publisher Contact": contact,
    "Publisher Organization": p.metadata?.publisherContactOrganisation,
    Homepage: p.metadata?.website,
    "Date Created": toSimpleDate(p.status.created_time),
    License: p.metadata?.licenseName,
    "License Text": p.metadata?.licenseFullText,
    "License Link": p.metadata?.licenseHref,
    UUID: p.uuid,
    "Qualified Name": p.nameId,
  }

  return (
    <DataDetailsTable
      data={data}
      links={["Homepage"]}
      valuesWithCopyEnabled={["UUID", "Qualified Name"]}
      cellSx={{ minWidth: "165px" }}
    />
  )
}

export default React.memo(MetadataTable)
