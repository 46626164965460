import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"

/* eslint-disable @typescript-eslint/indent */
export const notImplemented = () => {
  alert("Not implemented yet.")
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
    ? Subset<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? Subset<K[attr]> | null | undefined
    : K[attr]
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

export type Nullable<T> = { [K in keyof T]: T[K] | null }

export type Modify<T, R extends Partial<Record<keyof T, any> | any>> = Omit<T, keyof R> & R

export type Mapper<Type, K> = {
  [Property in keyof Type]: K
}

export const getRtkQueryErrorMessage = (error: SerializedError | FetchBaseQueryError) => {
  if ("status" in error) {
    return (error.data as string) || ""
  }
  return error.message
}

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return "0 Bytes"

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const checkIfProduction = () => process.env.NODE_ENV === "production"
